<template>
  <div>
    <div class="z-0 font-sane">
      <header>
        <ConnectWallet />
      </header>

      <div class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-2xl mt-10">
        <div class="sm:max-w-xl md:max-w-full lg:max-w-screen-2xl">
          <div
            class="px-4 sm:max-w-xl md:max-w-full lg:max-w-screen-2xl md:px-10 lg:px-8 lg:py-10"
          >
            <div class="flex justify-center">
              <div class="mt-10 text-center">
                <h3
                  class="text-3xl font-bold lg:text-3xl xl:text-3xl text-transparent bg-clip-text bg-gradient-to-r from-green-200 via-pink-300 to-blue-500 text-shadow-white"
                >
                  GPT-3, DALL·E, Midjourney, Stable Diffusion, <br />
                  ChatGPT Prompt Marketplace
                </h3>

                <h3
                  class="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-100 mt-5"
                >
                  Prompt art exhibition that share your prompts to the digital
                  world.
                </h3>
                <h3
                  class="text-lg mt-2 font-bold text-transparent bg-clip-text bg-gradient-to-r from-gray-200 to-gray-100 mt-2"
                >
                  The #1 website for prompt engineering. Search millions of AI
                  art images by models like Stable Diffusion, Midjourney...
                </h3>

                <br />
                <br />

                <div class="flex justify-center">
                  <div>
                    <router-link
                      to="/publicFree"
                      class="flex-none text-lg px-6 py-3 border rounded-xl text-gray-100 border-none bg-gradient-to-r from-blue-500 to-green-600 font-extrabold"
                    >
                      public prompt
                    </router-link>
                  </div>
                  <div>
                    <router-link
                      to="/sell"
                      class="text-lg px-6 py-3 border rounded-xl text-gray-100 border-none bg-gradient-to-r from-pink-500 to-blue-600 font-extrabold ml-2 animate-pulse"
                    >
                      sell prompt
                    </router-link>
                  </div>
                </div>

                <div>
                  <div class="flex justify-center mt-10">
                    <div
                      class="grid grid-cols-3 gap-1 lg:grid-cols-3 md:grid-cols-3 text-center"
                    >
                      <router-link
                        class="border-solid border p-2 m-1 rounded-2xl border-light-blue-500 bg-black hover:bg-blue-900 text-white flex items-center justify-center"
                        v-for="b in brand"
                        :key="b"
                        :to="'search/' + b.name"
                      >
                        {{ b.name }}
                      </router-link>
                    </div>
                  </div>
                </div>

                <div class="grid grid-cols-3 mt-16 text-gray-100">
                  <div class="text-center">
                    <h3 class="text-gray-300">Creator</h3>

                    <h3 class="text-2xl mt-1 font-semibold font-sans">
                      {{ countUsers }}
                    </h3>
                  </div>
                  <div class="text-center">
                    <h3 class="text-gray-300">Prompt</h3>
                    <h3 class="text-2xl mt-1 font-semibold font-sans">
                      {{ countPrompt }}
                    </h3>
                  </div>
                  <div class="text-center">
                    <h3 class="text-gray-300">Free Prompt</h3>
                    <h3 class="text-2xl mt-1 font-semibold font-sans">
                      {{ countPromptFree }}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <br />

            <!-- <div class="">
              <div class="grid grid-cols-1 gap-4 lg:grid-cols-2">
                <div class="mt-10">
                  <h3
                    class="text-3xl font-bold lg:text-3xl xl:text-3xl text-transparent bg-clip-text bg-gradient-to-r from-green-200 via-pink-300 to-blue-500 text-shadow-white"
                  >
                    GPT-3, DALL·E, Midjourney, Stable Diffusion, ChatGPT Prompt
                    Marketplace
                  </h3>

                  <h3
                    class="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-300 mt-5"
                  >
                    Prompt art exhibition that share your prompts to the digital
                    world.
                  </h3>
                  <h3
                    class="text-sm font-bold text-transparent bg-clip-text bg-gradient-to-r from-gray-200 to-gray-300 mt-2"
                  >
                    The #1 website for prompt engineering. Search millions of AI
                    art images by models like Stable Diffusion, Midjourney...
                  </h3>

                  <br />

                  <form
                    class="flex justify-center border-2 py-1 sm:py-3 px-3 rounded-lg max-w-xl mt-2 bg-gray-600"
                    v-on:submit.prevent="submitSearch"
                  >
                    <input
                      placeholder="Search AI images"
                      v-model="searchPrompt"
                      class="flex-grow outline-none text-lg bg-gray-600 text-gray-100"
                    />

                    <router-link
                      :to="'search/' + searchPrompt"
                      v-if="searchPrompt"
                    >
                      <img
                        class="w-9 cursor-pointer"
                        src="@/assets/gif/zoom.gif"
                        alt=""
                      />
                    </router-link>

                    <router-link :to="'search'" v-else>
                      <img
                        class="w-9 cursor-pointer"
                        src="@/assets/gif/zoom.gif"
                        alt=""
                      />
                    </router-link>
                  </form>

                  <div class="mt-2 text-blue-300 text-xs flex">
                    <div>
                      <div
                        class="grid grid-cols-3 gap-1 lg:grid-cols-4 md:grid-cols-4"
                      >
                        <router-link
                          class="border-solid border p-2 m-1 rounded border-light-blue-500 flex flex-wrap content-center bg-gradient-to-r from-gray-500 to-gray-600 hover:from-blue-500 hover:to-green-600"
                          v-for="b in brand"
                          :key="b"
                          :to="'search/' + b.name"
                        >
                          <i
                            class="fa fa-search mr-0.5 text-blue-200 text-shadow-white"
                          ></i>
                          {{ b.name }}
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flex flex-col items-center">
                  <div class="hidden lg:block">
                    <img src="/img/home.png" class="max-h-96 rounded-3xl" />

                    <div class="flex flex-col items-center mt-1">
                      <a class="text-xs text-gray-200"> By midjourney </a>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>

      <Free_Prompts />
      <Featured_Prompts />
      <!-- <Trending_Prompts /> -->
      <Newest_Prompts />

      <br />
      <br />

      <div
        class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl mt-10 p-5"
      >
        <div class="sm:max-w-xl md:max-w-full lg:max-w-screen-2xl">
          <div
            class="px-4 sm:max-w-xl md:max-w-full lg:max-w-screen-2xl md:px-10 lg:px-8 lg:py-10"
          >
            <div class="">
              <div class="grid grid-cols-1 gap-4 lg:grid-cols-2">
                <div class="mt-10">
                  <h3
                    class="text-3xl font-bold lg:text-3xl xl:text-3xl text-transparent bg-clip-text bg-gradient-to-r from-gray-200 via-red-300 to-blue-500 text-shadow-white"
                  >
                    Earn from your Prompt Engineering skills
                  </h3>

                  <h3
                    class="text-lg font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-200 to-blue-400 mt-2"
                  >
                    PromptBase is an early marketplace for DALL·E, Midjourney,
                    Stable Diffusion & GPT-3 prompts.
                  </h3>

                  <h3
                    class="text-lg font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-200 to-blue-400 mt-2"
                  >
                    Sell your prompts on PromptBase and earn from your prompt
                    crafting skills.
                  </h3>

                  <h3
                    class="text-lg font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-200 to-blue-400 mt-2"
                  >
                    Upload your prompt, connect with Stripe, and become a seller
                    in just 2 minutes.
                  </h3>

                  <br />

                  <button
                    class="w-42 flex-none text-lg text-base px-6 py-2 border rounded text-gray-700 border-none bg-gradient-to-r from-gray-200 to-gray-300"
                  >
                    Sell a prompt
                  </button>
                </div>

                <div class="flex flex-col items-center">
                  <div class="hidden sm:block">
                    <img src="/img/home2.png" class="max-h-96 rounded-3xl" />
                    <!-- <img
                      src="https://i.gyazo.com/c58d6b7349191f2feb476c29a0b485ae.jpg"
                    /> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Sexy_Prompt />
      <Trending_Prompts />

      <StickFooter />
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/Footer.vue";
import FundSummary from "@/components/FundSummary.vue";
import Social from "@/components/Social.vue";
import LiveTx from "@/components/LiveTx.vue";
import Stats from "@/components/Stats.vue";
import OurTeam from "@/components/OurTeam.vue";
import Roadmap from "@/components/Roadmap.vue";
import Stake from "@/components/Stake.vue";

import Featured_Prompts from "./home/Featured_Prompts.vue";
import Newest_Prompts from "./home/Newest_Prompts.vue";
import Trending_Prompts from "./home/Trending_Prompts.vue";
import Free_Prompts from "./home/Free_Prompts.vue";
import Sexy_Prompt from "./home/Sexy_Prompt.vue";

import Faq from "./faq.vue";

import { mapGetters, mapActions } from "vuex";

import NFTs from "@/api/NFTs";
import Prompt from "@/api/prompt";

import { POOL_ADDRESS, VERSION } from "../../config";

import { defineComponent } from "vue";
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";

// import { products, main_product, products_2 } from "../../products";

export default {
  data() {
    return {
      version: VERSION,
      products: [],
      products_2: [],

      ref: this.$route.params.ref,
      searchPrompt: null,
      brand: [
        { name: "DALL-E", value: false },
        { name: "Midjourney", value: false },
        { name: "PromptBase", value: false },
        { name: "BlueWillow", value: false },
        { name: "Stable Diffusion", value: false },
        { name: "ChatGPT", value: false },
      ],
      countUsers: 0,
      countPromptFree: 0,
      countPrompt: 0,
    };
  },
  components: {
    Social,
    LiveTx,
    Stats,
    OurTeam,
    Roadmap,
    Stake,
    ConnectWallet,
    StickFooter,
    FundSummary,
    Carousel,
    Slide,
    Pagination,
    Navigation,
    Featured_Prompts,
    Trending_Prompts,
    Newest_Prompts,
    Faq,
    Free_Prompts,
    Sexy_Prompt,
  },
  methods: {
    fixed(a) {
      return (a.toFixed(0) / 1).toLocaleString();
    },
    digit(a, digi) {
      if (a) {
        return a.toLocaleString(undefined, {
          minimumFractionDigits: digi,
        });
      }
    },

    submitSearch(data) {
      if (this.searchPrompt) {
        this.$router.push("/search/" + this.searchPrompt);
      } else {
        this.$router.push("/search");
      }
    },
    async getDataHomeDetail() {
      await Prompt.homeDetail()
        .then((res) => {
          this.countUsers = res.data.countUsers;
          this.countPromptFree = res.data.countPromptFree;
          this.countPrompt = res.data.countPrompt;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.getDataHomeDetail();

    if (this.$route.params.ref) {
      localStorage.setItem("Ref", JSON.stringify(this.$route.params.ref));
    } else {
      localStorage.setItem("Ref", JSON.stringify(POOL_ADDRESS));
    }
  },
};
</script>
